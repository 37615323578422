import React, { useState } from 'react';
import styled from 'styled-components';
import Card from '../../atoms/Card';
import CardTitle from '../../atoms/CardTitle';
import CardSubtitle from '../../atoms/CardSubtitle';
import {
    ATELIERREUNION,
    ATELIERBILAN,
    ATELIEREMOTION,
    ATELIERPOID,
    ATELIERPHYSIQUE,
    ATELIERMANUELLE,
    ATELIERTNS,
    ATELIERPAIRS
  } from '../../../constants/images';



// Styled Components
const PageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding-top: 0px; // Adjust based on your navbar height
  padding-bottom: 35px;
`;

const PageContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2px 0px;
`;

const PageTitle = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 25px;
  padding-top: 0px;
`;

const WorkshopsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  padding-bottom: 48px;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const WorkshopImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 16px;
`;

const ObjectiveTitle = styled.p`
  font-weight: bold;
  margin-bottom: 8px;
`;

const ObjectiveText = styled.p`
  color: #4b5563;
  margin-bottom: 16px;
  line-height: 1.5;
`;

const ReadMoreLink = styled.a`
  display: inline-block;
  padding: 8px 16px;
  background-color: #f3f4f6;
  color: #4b5563;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color 0.2s;
  margin-bottom: 16px;
  
  &:hover {
    background-color: #e5e7eb;
  }
`;

const SelectWrapper = styled.div`
  margin-top: 16px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin: 12px 0;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  background-color: white;
`;

const BookButton = styled.a`
  display: inline-block;
  padding: 12px 24px;
  background-color: #cdebf3;
  color: black;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color 0.2s;
  margin-top: 8px;
  
  &:hover {
    background-color: rgb(175, 230, 245);
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 16px;
`;

const CardContent = styled.div`
  padding: 16px;
`;

const Reservation = () => {

  const workshops = [
    {
      id: 'reunion',
      title: "Réunion d'information générale",
      image: ATELIERREUNION,
      objective: "Découvrir le programme TABAPSY et ses différents ateliers. Cette séance permet de comprendre les aides disponibles pour l'arrêt du tabac et de poser toutes vos questions sur le programme.",
      readMoreLink: "https://tabapsy.fr/ateliers/reunion"
    },
    {
      id: 'bilan',
      title: "Atelier de bilan",
      image: ATELIERBILAN,
      objective: "Réaliser un bilan personnalisé de votre consommation de tabac, évaluer votre niveau de dépendance et définir ensemble votre stratégie d'arrêt ou de réduction adaptée à votre situation.",
      readMoreLink: "https://tabapsy.fr/ateliers/bilan"
    },
    {
      id: 'traitements',
      title: "Atelier TNS et cigarette électronique",
      image: ATELIERTNS,
      objective: "Comprendre les différents traitements nicotiniques de substitution (TNS) et la cigarette électronique. Découvrir les aides médicamenteuses disponibles et apprendre à les utiliser efficacement pour réussir votre sevrage.",
      readMoreLink: "https://tabapsy.fr/ateliers/traitements"
    },
    {
      id: 'emotions',
      title: "Atelier gestion des émotions",
      image: ATELIEREMOTION,
      objective: "Apprendre à gérer le stress, l'anxiété et les émotions difficiles sans avoir recours à la cigarette. Découvrir des techniques de relaxation et de gestion émotionnelle pour mieux vivre votre sevrage tabagique.",
      readMoreLink: "https://tabapsy.fr/ateliers/emotions"
    },
    {
      id: 'poid',
      title: "Atelier prise de poids",
      image: ATELIERPOID,
      objective: "Comprendre et anticiper les changements liés à l'arrêt du tabac sur votre poids. Apprendre à adopter une alimentation équilibrée et des habitudes saines pour maintenir votre poids pendant le sevrage tabagique.",
      readMoreLink: "https://tabapsy.fr/ateliers/poid"
    },
    {
      id: 'activites-physiques',
      title: "Atelier activités physiques",
      image: ATELIERPHYSIQUE,
      objective: "Découvrir comment l'activité physique peut vous aider dans votre démarche d'arrêt du tabac. Pratiquer des exercices adaptés à votre niveau et apprendre à intégrer l'activité physique dans votre quotidien.",
      readMoreLink: "https://tabapsy.fr/ateliers/activites-physiques"
    },
    {
      id: 'activites-manuelles',
      title: "Atelier activités manuelles",
      image: ATELIERMANUELLE,
      objective: "Explorer différentes activités manuelles et créatives pour occuper vos mains et votre esprit pendant le sevrage tabagique. Ces activités vous aideront à gérer les envies de fumer et à développer de nouveaux centres d'intérêt.",
      readMoreLink: "https://tabapsy.frhttps://tabapsy.fr/ateliers/activites-manuelles"
    },
    {
      id: 'soutien-pairs',
      title: "Groupe de soutien entre pairs",
      image: ATELIERPAIRS,
      objective: "Échanger avec d'autres personnes qui vivent la même expérience que vous. Partager vos difficultés, vos succès et vos stratégies dans un cadre bienveillant et encourageant. Le groupe permet de se sentir moins seul et de trouver du soutien dans votre démarche.",
      readMoreLink: "https://tabapsy.fr/ateliers/soutien-pairs"
    }
  ];

  const cmpOptions = [
    { value: 'cmp1', label: 'CMP de Lillers' },
    { value: 'cmp2', label: 'CMP de Noeux les mines' },
    { value: 'cmp3', label: 'EPSM des Flandres (CMP de Bailleul, Merville et Hazebrouck)' },
    { value: 'cmp4', label: '94G16 : CMP Mary Barnes-Charenton et Lilas-Alfortville' }
  ];

  const doodleLinks = {
    cmp1: {
      "reunion": "https://doodle.com/sign-up-sheet/participate/ac3d9982-b0ba-4aa7-b8fd-81037127a100/select",
      "bilan": "https://doodle.com/sign-up-sheet/participate/8ba06253-75ce-4f1a-9f80-603833409c02/select",
      "traitements": "https://doodle.com/sign-up-sheet/participate/730a6404-1622-4779-8400-64a5718d9a5c/select",
      "emotions": "https://doodle.com/sign-up-sheet/participate/37fc9643-d436-48a7-ab19-ee97d6164ca9/select",
      "poid": "https://doodle.com/sign-up-sheet/participate/f1507bf3-6357-4d01-bc35-b9775a07b96a/select",
      "activites-physiques": "https://doodle.com/sign-up-sheet/participate/e15b9496-ef59-453f-9ba1-39fa71dd3682/select",
      "activites-manuelles": "https://doodle.com/sign-up-sheet/participate/527973fe-cc36-4c44-814a-c3bd992dafef/select",
      "soutien-pairs": "https://doodle.com/sign-up-sheet/participate/e6e2d2b7-17d2-4095-841b-360c9c463b6d/select"
    },
    cmp2: {
      "reunion": "https://doodle.com/sign-up-sheet/participate/42a59cda-e544-4f9e-9c04-6e03e9e86180/select",
      "bilan": "https://doodle.com/sign-up-sheet/participate/a602909f-8990-403b-9e00-098bd07572d3/select",
      "traitements": "https://doodle.com/sign-up-sheet/participate/be0504ac-624c-4151-a7f0-06936b39d519/select",
      "emotions": "https://doodle.com/sign-up-sheet/participate/ad4fecc7-a425-48f9-ab25-bb576501dc24/select",
      "poid": "https://doodle.com/sign-up-sheet/participate/0e8488d9-0007-4e22-a2b3-d481193eec17/select",
      "activites-physiques": "https://doodle.com/sign-up-sheet/participate/ca0d6aff-18a8-4276-9f3a-7fb464c17937/select",
      "activites-manuelles": "https://doodle.com/sign-up-sheet/participate/00329f2d-10f8-47c9-b26c-eeae2c25a96b/select",
      "soutien-pairs": "https://doodle.com/sign-up-sheet/participate/825ad015-4e26-466d-addc-ae0f9d6e0adf/select"
    },
    cmp3: {
      "reunion": "https://doodle.com/sign-up-sheet/participate/d148be0c-08a7-45fa-a9e2-71606bbf69ae/select",
      "bilan": "https://doodle.com/sign-up-sheet/participate/60a4fe6a-6cd8-4bd2-9bf1-340fcb3aabff/select",
      "traitements": "https://doodle.com/sign-up-sheet/participate/9d9b6ec9-2044-4890-8cd0-78de489fb494/select",
      "poid": "https://doodle.com/sign-up-sheet/participate/0297c7db-db31-4e9a-82c0-012371406f30/select",
      "activites-physiques": "https://doodle.com/sign-up-sheet/participate/5db21d98-98c7-49de-ad56-a4aa449b1c7d/select",
      "activites-manuelles": "https://doodle.com/sign-up-sheet/participate/c17b6478-28e2-42fa-a0ce-efdd263375a1/select",
      "soutien-pairs": "https://doodle.com/sign-up-sheet/participate/8ae6f1b9-1a9c-493b-b86f-16207e47f4a4/select"
    },
    cmp4: {
      "reunion": "https://doodle.com/sign-up-sheet/participate/b4a4a5eb-3b14-4d7f-bcd9-bb3b561dc6b3/select",
      "bilan": "https://doodle.com/sign-up-sheet/participate/923dad7a-4297-4283-856f-33dab6d72763/select",
      "traitements": "https://doodle.com/sign-up-sheet/participate/fcdd56b8-869a-4309-9c9f-b76bc05f21b2/select",
      "emotions": "https://doodle.com/sign-up-sheet/participate/d78febeb-59c1-4e3b-b24d-a3732aaa8052/select",
      "poid": "https://doodle.com/sign-up-sheet/participate/cb533e2b-85da-457b-9182-b464fdb33696/select",
      "activites-physiques": "https://doodle.com/sign-up-sheet/participate/b6389d79-e7f3-492c-af66-d92686b02f45/select",
      "activites-manuelles": "https://doodle.com/sign-up-sheet/participate/b2078464-b2e6-4678-917b-7f556375445a/select",
      "soutien-pairs": "https://doodle.com/sign-up-sheet/participate/cce18bea-d366-49ba-9d26-9d2e4563adc3/select"
    }

  };

  const [selectedCMPs, setSelectedCMPs] = useState({});

  const handleCMPChange = (workshopId, cmpValue) => {
    setSelectedCMPs(prev => ({
      ...prev,
      [workshopId]: cmpValue
    }));
  };

  const WorkshopCard = ({ workshop }) => {
    const selectedCMP = selectedCMPs[workshop.id];
    const doodleLink = selectedCMP && doodleLinks[selectedCMP]?.[workshop.id];

    return (
      <Card>
        <CardContent>
          <WorkshopImage 
            src={workshop.image} 
            alt={workshop.title}
          />
          <CardTitle>{workshop.title}</CardTitle>
          <div>
            <ObjectiveTitle>Objectif :</ObjectiveTitle>
            <ObjectiveText>{workshop.objective}</ObjectiveText>
            <ReadMoreLink href={workshop.readMoreLink}>
              Lire plus
            </ReadMoreLink>
          </div>
          <SelectWrapper>
            <CardSubtitle>Choisissez votre CMP</CardSubtitle>
            <Select
              value={selectedCMPs[workshop.id] || ''}
              onChange={(e) => handleCMPChange(workshop.id, e.target.value)}
            >
              <option value="" disabled>Sélectionnez un CMP</option>
              {cmpOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            {doodleLink && (
              <ButtonWrapper>
                <BookButton
                  href={doodleLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Réserver un créneau
                </BookButton>
              </ButtonWrapper>
            )}
          </SelectWrapper>
        </CardContent>
      </Card>
    );
  };

  return (
    <PageWrapper>
      <PageContainer>
        <PageTitle>
          Réservez des Ateliers de Tabapsy
        </PageTitle>
        
        <WorkshopsGrid>
          {workshops.map(workshop => (
            <WorkshopCard key={workshop.id} workshop={workshop} />
          ))}
        </WorkshopsGrid>
      </PageContainer>
    </PageWrapper>
  );
};

export default Reservation;