import { IonContent, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { menuOutline } from "ionicons/icons";
import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { useConfig } from "../../../contexts/ConfigContext";
import Icon from "../../atoms/Icon";
import Menu from "../../molecules/Menu/Menu";
import NavButtons from "../../molecules/NavButtons/NavButtons";
import { useResonanceLayout } from "./ResoncanceContext";
import { updateManifest } from "../../../helper/manifest-update";
import ScreenTitle from "../../atoms/ScreenTitle";
import { MINI_LOGO, LOGO } from '../../../constants/images';
import { HEADER_BACKGROUND_MAIN } from '../../../constants/images';

interface ResonanceLayoutProps {
  children?: ReactElement;
  isConnected?: boolean;
}

const ResonanceLayout = ({
  children,
  isConnected,
}: ResonanceLayoutProps): ReactElement => {
  const {
    disableNav,
    disableHeader,
    isMenuVisible,
    setResonanceLayout,
    backgroundColor,
    title,
    welcomePage,
  } = useResonanceLayout();

  const { t } = useTranslation();

  const { app } = useConfig();

  const setIsMenuVisible = (value: boolean) => {
    setResonanceLayout({
      isMenuVisible: value,
    });
  };

  useEffect(() => {
    updateManifest(app?.name, app?.logo);
  },[app])

  return (
    <IonPage>
      {!disableHeader && !welcomePage && (
        <BackgroundImage>
          <ScreenTitle>{t(title)}</ScreenTitle>
          <Logo src={MINI_LOGO} />
          <MenuButton onClick={() => setIsMenuVisible(true)}>
            <Icon icon={menuOutline} size="large" />
          </MenuButton>
        </BackgroundImage>
      )}

      {!disableHeader && welcomePage && (
        <BackgroundImage>
          <ScreenTitle>{t(title)}</ScreenTitle>
          <BigLogo src={LOGO} />
          <MenuButton onClick={() => setIsMenuVisible(true)}>
            <Icon icon={menuOutline} size="large" />
          </MenuButton>
        </BackgroundImage>
      )}
      {isMenuVisible && (
        <Menu
          onClose={() => setIsMenuVisible(false)}
          isConnected={isConnected}
        />
      )}

      <div style={{ backgroundColor: "eee", height: {disableHeader} ? "100%" : "calc(100vh - (55vh * (163 / 390)))" }}>
        <div
          className="w-container h-100 "
          style={{ backgroundColor, display: "flex", flexDirection: "column" }}
        >
          <IonContent>{children}</IonContent>
          {isConnected && !disableNav && <NavButtons />}
        </div>
      </div>
    </IonPage>
  );
};

const Logo = styled.img`
  position: absolute;
  max-width: 22.5%;
  max-height: 25vh; 
  top: 40%;
  left: 0;
  transform: translateY(-50%);
`;

const BigLogo = styled.img`
  position: absolute;
  max-width: 20%;
  max-height: 11vh; 
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 480px) {
    max-height: 20vh;
    max-width: 35%;
}
`;

const BackgroundImage = styled.div`
  position: relative;
  width: 100%;
  height: calc(65vh * (163 / 390));
  max-height: 180px;
  background-image: url(${HEADER_BACKGROUND_MAIN});
  background-size: contain;
  background-position: top center;
  background-repeat: repeat-x;
  background-color: white;
`;

const MenuButton = styled.div`
  position: absolute;
  text-align: right;
  top: 40%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block;
`;

export default ResonanceLayout;