import React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

const CustomHomePage = (): React.ReactElement => {
    let history = useHistory();
    const { study_slug } = useParams<{ study_slug: string }>();

    const handleVisitSite = () => {
        window.open("https://www.tabapsy.fr", "_blank");
    };

    const handleAccessQuestionnaires = () => {
        history.push(`/${study_slug}/resonance/sequences`);
    };

    return (
        <ScrollableContainer>
            <ContentWrapper>
                <HeaderSection>
                    <Title>Bienvenue à Tabapsy</Title>
                    <Subtitle>Votre programme d'accompagnement personnalisé</Subtitle>
                </HeaderSection>

                <Section>
                    <Paragraph>
                        Votre CMP met en place l'intervention Tabapsy, qui offre un dispositif
                        d'accompagnement aux personnes fumeuses suivies en CMP. Vous n'êtes
                        pas obligé(e) de participer à ce dispositif ou même d'avoir envie
                        d'arrêter de fumer pour participer à l'étude et remplir les
                        questionnaires.
                    </Paragraph>

                    <ActionCard>
                        <Paragraph>
                            Pour en savoir plus, visitez notre site dédié :
                        </Paragraph>
                        <ButtonContainer>
                            <StyledButton onClick={handleVisitSite}>
                                Visitez le site web d'intervention
                            </StyledButton>
                        </ButtonContainer>
                        <Paragraph style={{ marginBottom: 0 }}>
                            Vous y trouverez des informations détaillées et un lien pour vous
                            inscrire aux différentes composantes du dispositif.
                        </Paragraph>
                    </ActionCard>

                    <Paragraph>
                        La durée de votre participation à l'étude est de 6 mois. Pendant cette
                        période, nous vous solliciterons 3 fois pour remplir le même
                        questionnaire. Si vous décidez de participer au dispositif, des
                        questionnaires additionnels vous seront proposés pour recueillir votre
                        avis.
                    </Paragraph>

                    <ActionCard>
                        <Paragraph>Pour accéder aux questionnaires :</Paragraph>
                        <ButtonContainer>
                            <StyledButton primary onClick={handleAccessQuestionnaires}>
                                Commencez à répondre aux questionnaires
                            </StyledButton>
                        </ButtonContainer>
                    </ActionCard>

                    <ThankYouMessage>
                        Encore merci pour votre contribution à cette recherche !
                    </ThankYouMessage>
                </Section>
            </ContentWrapper>
        </ScrollableContainer>
    );
};

const ScrollableContainer = styled.div`
    height: 100vh;
    overflow-y: auto;
    background-color:rgb(255, 255, 255);
    padding: 0px 20px 250px;
`;

const ContentWrapper = styled.div`
    max-width: 800px;
    margin: 0 auto 40px auto;
    padding: 19px;
    background: #ffffff;
    border-radius: 16px;
    // box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
`;

const HeaderSection = styled.div`
    text-align: center;
    margin-bottom: 40px;
    padding-bottom: 30px;
    border-bottom: 2px solid #cdebf3;
`;

const Title = styled.h1`
    font-size: 2.4rem;
    color: rgb(57 57 57);
    margin-bottom: 16px;
    font-weight: 700;
    margin-top:0px;
`;

const Subtitle = styled.h2`
    font-size: 1.2rem;
    color: #4a5568;
    font-weight: 400;
`;

const Section = styled.div`
    margin-bottom: 30px;
`;

const Paragraph = styled.p`
    font-size: 1.1rem;
    line-height: 1.7;
    margin-bottom: 24px;
    margin-top: 0px;
    color: #2d3748;
`;

const ActionCard = styled.div`
    background: rgb(205 235 243 / 15%);
    padding: 15px;
    border-radius: 12px;
    margin: 30px 0;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 24px 0;
`;

const StyledButton = styled.button<{ primary?: boolean }>`
    display: inline-block;
    padding: 16px 28px;
    font-size: 1.1rem;
    font-weight: 600;
    color: rgb(57 57 57);
    background-color: #cdebf3;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
        background-color:rgb(175, 230, 245) ;
        transform: translateY(-1px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &:active {
        transform: translateY(0);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
`;

const CallToAction = styled.div`
    text-align: center;
    margin: 40px 0;
    padding: 32px;
    background: linear-gradient(to right, #ebf8ff, #e6fffa);
    border-radius: 12px;
`;

const ThankYouMessage = styled.p`
    text-align: center;
    font-size: 1.2rem;
    color: #2d3748;
    font-weight: 500;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 2px solid #cdebf3;
`;

export default CustomHomePage;