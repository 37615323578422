import { deleteApi, patchApi, postApi, getApi, ParamsType } from "./resonanceApi";
import { AxiosResponse } from 'axios';
import { LoginResponseType, LoginType, PostUserGamification } from "../types/welcome";
import { UserType } from "../types/session";
import { postChangePasswordBody, postInitIdentificationType, ValidateUserInfoRequestBody, ValidateUserInfoResponse } from "../types/user.types";
import { InclusionAnswerType, PatchUserType, postConsentRequestType, PostInclusionQuestionType, PostSiteType, PostUserResponseType, PostUserType, StratQuestionType } from "../types/welcome";

export enum UserApiUrlsEnum {
  PATCH_LANG = 'studies/{studyId}/users/current',
  LOGIN = 'studies/{studyId}/roles/{roleId}/sessions',
  LOGOUT = 'resonance/sessions',
  GET_CURRENT_USER = 'studies/{studyId}/users/current',
  CHANGE_PWD = 'studies/{studyId}/users/password',
  POST_INCLUSION = 'studies/{studyId}/users/{userId}/inclusion_questions',
  POST_TEST_ACCOUNT = 'studies/{studyId}/users/{userId}/test_account',
  POST_USER = 'studies/{studyId}/users',
  PATCH_USER = 'studies/{studyId}/users/{userId}',
  POST_GAMIFICATION = 'studies/{studyId}/users/{userId}/gamification',
  GET_STRAT_QUESTIONS = 'studies/{studyId}/roles/{roleId}/stratification_questions',
  GET_CONSENT_BY_STRAT_QUESTIONS = 'studies/{studyId}/roles/{roleId}/stratification_questions/getconsent',
  SET_SITE = 'studies/{studyId}/users/current/sites',
  SET_CONSENT = 'studies/{studyId}/users/{userId}/consents',
  POST_INIT_IDENTIFICATION = 'studies/{studyId}/users/init_identification',
  VALIDATE_USER_INFO = 'studies/{studyId}/users/current/check',
  FORGOT_PASSWORD = 'studies/{studyId}/users/forgotten_password',
  REST_PASSWORD = 'studies/{studyId}/users/reset_password',
  GET_TOTAL_USER = 'studies/{studyId}/users/total'
}

export const postInclusionAnswers = (
  studyId: number,
  userId: number,
  answers: InclusionAnswerType[]
): Promise<AxiosResponse<PostInclusionQuestionType>> => {
  const url = UserApiUrlsEnum.POST_INCLUSION;
  return postApi<PostInclusionQuestionType>(url, { studyId, userId }, answers);
};

export const postTestAccount = (studyId: number, userId: number): Promise<AxiosResponse<void>> => {
  const url = UserApiUrlsEnum.POST_TEST_ACCOUNT;
  return postApi<void>(url, { studyId, userId }, {});
}

export const postNewUser = (studyId: number, userInfos: PostUserType): Promise<AxiosResponse<PostUserResponseType>> => {
  const url = UserApiUrlsEnum.POST_USER;
  return postApi<PostUserResponseType>(url, { studyId }, userInfos);
};

export const patchUser = (studyId: number, userId: number, userInfos: PatchUserType): Promise<AxiosResponse<void>> => {
  const url = UserApiUrlsEnum.PATCH_USER;
  return patchApi<void>(url, { studyId, userId }, userInfos);
};

export const postUserGamification = (studyId: number, userId: number, userInfos: PostUserGamification): Promise<AxiosResponse<PostUserGamification>> => {
  const url = UserApiUrlsEnum.POST_GAMIFICATION;
  const res = postApi<PostUserGamification>(url, { studyId, userId }, userInfos);
  return res;
};

export const getStratificationQuestions = ({ studyId, roleId }: ParamsType): Promise<AxiosResponse<StratQuestionType[]>> => {
  const url = UserApiUrlsEnum.GET_STRAT_QUESTIONS;
  return getApi<StratQuestionType[]>(url, { studyId, roleId });
};

export const postConsentByStratificationQuestions = ({ studyId, roleId, stratification_questions }: ParamsType): Promise<AxiosResponse<number>> => {
  const url = UserApiUrlsEnum.GET_CONSENT_BY_STRAT_QUESTIONS;
  return postApi<number>(url, { studyId, roleId }, {stratification_questions});
}

export const postSite = (studyId: number, body: PostSiteType): Promise<AxiosResponse> => {
  const url = UserApiUrlsEnum.SET_SITE;
  return postApi(url, { studyId }, body);
};

export const postConsent = (studyId: number, userId: number, body: postConsentRequestType) => {
  const url = UserApiUrlsEnum.SET_CONSENT;
  return postApi(url, { studyId, userId }, body)
}

export const login = (studyId: number, roleId: number, body: LoginType): Promise<AxiosResponse<LoginResponseType>> => {
  const url = UserApiUrlsEnum.LOGIN;
  return postApi<LoginResponseType>(url, { studyId, roleId }, body);
}

export const logout = (): Promise<AxiosResponse<LoginResponseType>> => {
  const url = UserApiUrlsEnum.LOGOUT;
  return deleteApi<LoginResponseType>(url);
}

export const getCurrentUser = (studyId: number): Promise<AxiosResponse<UserType>> => {
  const url = UserApiUrlsEnum.GET_CURRENT_USER;
  return getApi<UserType>(url, { studyId });
};
export const changePassword = (params: { studyId: number }, body: postChangePasswordBody): Promise<AxiosResponse<void>> => {
  const url = UserApiUrlsEnum.CHANGE_PWD;
  return patchApi(url, params, body)
}

export const forgotPassword = (params: { studyId: number }, body: { login: string }): Promise<AxiosResponse<void>> => {
  const url = UserApiUrlsEnum.FORGOT_PASSWORD;
  return postApi(url, params, body)
}

export const resetPassword = (params: { studyId: number }, body: { login: string, reset_password_token: string, password: string, password_confirmation: string }): Promise<AxiosResponse<void>> => {
  const url = UserApiUrlsEnum.REST_PASSWORD;
  return postApi(url, params, body)
}


export const patchLang = (studyId: number, body: { lang: string }): Promise<AxiosResponse> => {
  const url = UserApiUrlsEnum.PATCH_LANG;
  return patchApi(url, { studyId }, body);
}


export const postInitIdentification = (params: { studyId: number }, body: postInitIdentificationType): Promise<AxiosResponse<PostUserResponseType>> => {
  const url = UserApiUrlsEnum.POST_INIT_IDENTIFICATION;
  return postApi(url, params, body);
}

export const validateUserInfo = (params: { studyId: number }, body: ValidateUserInfoRequestBody): Promise<AxiosResponse<ValidateUserInfoResponse>> => {
  const url = UserApiUrlsEnum.VALIDATE_USER_INFO;
  return postApi(url, params, body);
}

export const getTotalUser = (studyId: number): Promise<AxiosResponse> => {
  const url = UserApiUrlsEnum.GET_TOTAL_USER;
  return getApi(url, { studyId });
};