export const profile = {
    'profile_account': 'Mon compte',
    'profile_name': 'Nom complet',
    'profile_email': 'Email',
    'profile_change_password': 'Modifier mot de passe',
    'profile_phone': 'Téléphone',
    'profile_study_info': "Les informations de l'étude",
    'profile_view_consent': "Afficher consentement",
    'profile_opt_out': "Se désinscrire de l'étude",
    'profile_opt_out_message': "Voulez-vous quitter l'étude ?",
    'profile_allow_access_data': "Conserver mes données de manière anonyme",
    'profile_website': "Site Web de recherche",
    'profile_contact': "Contacter les chercheurs",
    'profile_report_bug': 'Signaler un bug',
    'mention_legal': 'Mention légale'
}