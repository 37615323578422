
import { useIonToast } from "@ionic/react";

interface useToastOptions {
}

const useToast = (options?: useToastOptions) => {
  const [present] = useIonToast();

  return (message: string) => {
    if (!message) return; // Prevent rendering if message is empty
    present({
        message: message,
        duration: 2000,
        cssClass: 'custom-toast',
        ...options,
    });
};
}

export default useToast;