import { IonModal } from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
  isOpen: boolean;
  onDismiss(): void;
}

const LegalMentionModal: FC<Props> = ({ isOpen, onDismiss }) => {
  const { t } = useTranslation();

  return (
    <IonModal
      cssClass="resonance-modal"
      isOpen={isOpen}
      backdropDismiss={true}
      onWillDismiss={onDismiss}
    >
      <Container onClick={onDismiss}>
        <Card onClick={(e) => e.stopPropagation()}>
          <Title>{t("mention_legal")}</Title>
          <Message>
            Logo réalisé par Nancy de Jesus, 94G16, Hôpitaux Paris Est Val de
            Marne.
          </Message>
          <Buttons>
            <ModalButton onClick={onDismiss}>{t("common_close")}</ModalButton>
          </Buttons>
        </Card>
      </Container>
    </IonModal>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const Message = styled.div`
  padding: 10px 0;
  font-size: 14px;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ModalButton = styled.div`
  padding: 10px 20px;
  color: ${(props) => props.theme.primary.main};
  font-weight: bold;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.primary.main};
  border-radius: 5px;
`;

export default LegalMentionModal;
