import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { useResonanceLayout } from "../../organisms/ResonnanceLayout/ResoncanceContext";
import { ComponentType, QuestionnaireDetailsType } from "../../../types/Questionnaire";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFetch";
import { StudyContentType } from "../../../types/welcome";
import { useConfig } from "../../../contexts/ConfigContext";
import { useAuth } from "../../../contexts/AuthContext";
import { getStudyContent } from "../../../services/study.service";
import ResonanceLoader from "../../atoms/ResonanceLoader/ResonanceLoader";
import Info from "../../atoms/ErrorComponent";

const Stickers = (): ReactElement => {

  

    const { questionnaires } = useResonanceLayout();  

    const [totalQuestionnaire, setTotalQuestionnaire] = useState(0);
    const [isLoading,setIsLoading] = useState(true);
      
    useEffect(()=> {
      if (questionnaires) {
        let questionnairesCount = 0;
        for (const index in questionnaires) 
        {
          const questionnaire = questionnaires[index] as ComponentType;
          
          if ((questionnaire.answered_questions_count / questionnaire.questions_count !== 1))
            questionnairesCount++;
        }

        setTotalQuestionnaire(questionnairesCount);
        setIsLoading(false);
      }
    }, [questionnaires]);

    
  const { user } = useAuth();
  

    if (!isLoading && totalQuestionnaire > 0) {
      return (
        <Dot isSpecial={user.homepage_id === 7}>
          {totalQuestionnaire}
        </Dot>
      );
  } else {
    return <></>
  }
}

// Adjust styles based on `isSpecial` prop
const Dot = styled.div<{ isSpecial: boolean }>`
  position: absolute;
  top: 6%;
  left: ${({ isSpecial }) => (isSpecial ? "41%" : "34%")};
  transform: translateX(-50%);
  width: 19px;
  height: 19px;
  background-color: #ea4f3d;
  border-radius: 50%;
  z-index: 1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1200px) {
    /* Large screens */
    left: ${({ isSpecial }) => (isSpecial ? "38.5%" : "51%")};
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    /* Medium screens */
    left: ${({ isSpecial }) => (isSpecial ? "39%" : "52.5%")};
  }

  @media (max-width: 767px) {
    /* Small screens */
    left: ${({ isSpecial }) => (isSpecial ? "41.5%" : "53.5%")};
  }
`;


export default Stickers;