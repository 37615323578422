import { FC, ReactElement, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Icon from "../../atoms/Icon";
import Stickers from "./Sticker";
import { useConfig } from "../../../contexts/ConfigContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFetch";
import { StudyContentType } from "../../../types/welcome";
import { getStudyContent } from "../../../services/study.service";
import ResonanceLoader from "../../atoms/ResonanceLoader/ResonanceLoader";
import Info from "../../atoms/ErrorComponent";

const NavButtons = (): ReactElement => {
  const { study_slug } = useParams<{ study_slug: string }>();

  const [selected, setSelected] = useState<string>();


  const { user } = useAuth();
     
  
  return (
    <NavbarBackground>
    <NavBar>
      {/* <Tab
        selected={selected}
        setSelected={setSelected}
        iconSrc="/assets/icon/montagne_navbar.svg"
        tab="moutain"
        href={`/${study_slug}/resonance`}
      /> */}
      <Tab
        selected={selected}
        setSelected={setSelected}
        iconSrc="/assets/icon/profile.svg"
        tab="Home"
        href={`/${study_slug}/resonance`}
      />
      <Stickers />
      <Tab
        selected={selected}
        setSelected={setSelected}
        iconSrc="/assets/icon/questionnaire_navbar.svg"
        tab="questionnaires"
        href={`/${study_slug}/resonance/sequences`}
      />
       {/* Conditionally render the reservation tab */}
       { user.homepage_id === 7 && (
          <Tab
            selected={selected}
            setSelected={setSelected}
            iconSrc="/assets/icon/reserve2.svg"
            tab="reservation"
            href={`/${study_slug}/resonance/reservation`}
          />
        )}
      {/* <Tab
        selected={selected}
        setSelected={setSelected}
        iconSrc="/assets/icon/invitations_navbar.svg"
        tab="share"
        href={`/${study_slug}/resonance/invite`}
      /> */}
      <Tab
        selected={selected}
        setSelected={setSelected}
        iconSrc="/assets/icon/profil_navbar.svg"
        tab="profile"
        href={`/${study_slug}/resonance/profile`}
      />
    </NavBar>
    </NavbarBackground>
  );
};

interface TabProps {
  tab: string;
  href: string;
  iconSrc: string;
  badge?: string;
  selected: string;
  setSelected: (arg: string) => void;
}

const Tab: FC<TabProps> = ({ iconSrc, selected, setSelected, tab, href }) => {
  let history = useHistory();
  const handleClick = () => {
    setSelected(tab);
    history.push(href);
  };

  const isSelected = useMemo(
    () => href === history.location.pathname,
    [href, history.location.pathname]
  );

  return (
    <TabStyled onClick={handleClick}>
      <IconContain isSelected={isSelected}>
        <Icon src={iconSrc} size="large" />
      </IconContain>
    </TabStyled>
  );
};

const NavBar = styled.div`
  position: fixed;
  width: 85%;
  height: 60px;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: space-around;
  background-color: ${(props) => props.theme.primary.background};
  color: ${(props) => props.theme.primary.contrast};
  border-radius: 10px;
`;

const NavbarBackground = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90px; /* Adjust this to cover from slightly above the navbar to the bottom */
  background-color: #ffffff; /* Solid white background */
  z-index: 999; /* Ensure it stays above the page content */
`;

const TabStyled = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
`;

const IconContain = styled.div<{ isSelected: boolean }>`
  background-color: ${(props) =>
    props.isSelected ? `#FFFFFF` : `transparent`};
  opacity: ${(props) => (props.isSelected ? 1 : 0.8)};
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50px;
  transition: background-color 0.5s;
`;

export default NavButtons;
