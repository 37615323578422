import { removeCircleOutline, lockClosed, mail, logOut, person, phonePortrait, bug, list } from "ionicons/icons";
import { ReactElement, useContext, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import Container from "../../components/atoms/Container";
import Icon from "../../components/atoms/Icon";
import AuthContext from "../../contexts/AuthContext";
import { useConfig } from "../../contexts/ConfigContext";
import { IdentificationMethodEnum } from "../../types/profile";
import ChangePwdModal from "./ChangePasswordModal";
import LeaveStudyModal from "./LeaveStudyModal";
import LegalMentionModal from "./LegalMentionModal";
import { useResonanceLayout } from "../../components/organisms/ResonnanceLayout/ResoncanceContext";
import { useHistory, useParams } from "react-router"; // Added this line
import { resetCaches } from '../../services/questionnaire.service'; // Add this line




const Profile = (): ReactElement => {
  const { user, endSession } = useContext(AuthContext); // Modified to include endSession
  const { study_slug } = useParams<{ study_slug: string }>(); // Added this line
  const history = useHistory(); // Added this line

  const handleDisconnect = async () => {
    await endSession();
    resetCaches();
    history.replace(`/${study_slug}`, []);
  }; // Added this function

  
  const [isLeaveStudyModalOpen, setIsLeaveStudyModalOpen] = useState(false);
  const [isChangePwdModalOpen, setIsChangePwdModalOpen] = useState(false);
  const [isLegalMentionOpen, setIsLegalMentionOpen] = useState(false);
  const { selectedRole } = useConfig();

  const { setResonanceLayout } = useResonanceLayout();
  const theme = useTheme();
  
  useEffect(() => {
    setResonanceLayout({ });
  }, []);

  useEffect(() => {
    setResonanceLayout({
      disableHeader: false,
      disableNav: false,
      backgroundColor: "white",
      title: "profile_account",
    });

    return () =>
      setResonanceLayout({
        disableHeader: false,
        disableNav: false,
        backgroundColor: "white",
        title: "profile_account",
      });
  }, []);

  const canChangePassword = useMemo(
    () =>
      selectedRole?.signin_criteria?.method === IdentificationMethodEnum.EMAIL,
    [selectedRole]
  );

  const checkFakeEmail = (email) => /^user_.*@etudementalo\.fr$/.test(email) ? "" : email
  const { t } = useTranslation();

  const handleReportBug = () => {
    //window.location.href = "https://etude-mentalo.fr/contact/";
    window.location.href = "mailto:tabapsy@ghtpsy-npdc.fr?subject=Signalement de bug&body=Veuillez décrire le problème rencontré :";
  };
  const handleMentionLegal = () => {
    setIsLegalMentionOpen(true);
  }; 
  

  const handleOptOut: () => void = function () {
    setIsLeaveStudyModalOpen(true);
  };

  const handleChangePassword: () => void = function () {
    setIsChangePwdModalOpen(true);
  };

  return (
    <Container>
      <Section>
        {/* <Header>{t("profile_account")}</Header> */}
        {/* <Field>
          <Icon icon={person} />
          <FieldTitle>{t("profile_name")}</FieldTitle>
          <FieldValue>
            {user.attributes.firstname} {user.attributes.lastname}
          </FieldValue>
        </Field> */}

        <Field>
          <Icon icon={mail} />
          <FieldTitle>{t("profile_email")}</FieldTitle>
          <FieldValue>{checkFakeEmail(user.attributes.email)}</FieldValue>
        </Field>

        {canChangePassword && (
          <Field onClick={handleChangePassword}>
            <Icon icon={lockClosed} />
            <FieldTitle>{t("profile_change_password")}</FieldTitle>
          </Field>
        )}

        <Field>
          <Icon icon={phonePortrait} />
          <FieldTitle>{t("profile_phone")}</FieldTitle>
          <FieldValue>{user.attributes.phone_number==="0607080910" ? "" : user.attributes.phone_number}</FieldValue>
        </Field>
      </Section>

      <Section>
        <Header>{t("profile_study_info")}</Header>

        <Field onClick={handleOptOut}>
          <Icon icon={removeCircleOutline} />
          <FieldTitle>{t("profile_opt_out")}</FieldTitle>
        </Field>

        <Field onClick={handleReportBug}>
          <Icon icon={bug} />
          <FieldTitle>{t("profile_report_bug")}</FieldTitle>
        </Field>

        <Field onClick={handleMentionLegal}>
          <Icon icon={list} />
          <FieldTitle>{t("mention_legal")}</FieldTitle>
        </Field>

        <Field onClick={handleDisconnect}>
          <Icon icon={logOut} />
          <FieldTitle>{t("menu_logOut")}</FieldTitle>
        </Field>
      </Section>

      <LeaveStudyModal
        onDismiss={() => setIsLeaveStudyModalOpen(false)}
        isOpen={isLeaveStudyModalOpen}
      />
      <ChangePwdModal
        onDismiss={() => setIsChangePwdModalOpen(false)}
        isOpen={isChangePwdModalOpen}
      />
      <LegalMentionModal
        onDismiss={() => setIsLegalMentionOpen(false)} // Close Legal Mention Modal
        isOpen={isLegalMentionOpen} // Control modal visibility
      />
    </Container>
  );
};

const Section = styled.div`
  background-color: white;
`;

const Header = styled.div`
  background-color: ${(props) => props.theme.primary.background};
  padding: 20px;
  color: ${props => props.theme.primary.contrast};
  margin-bottom: 10px;
  font-size: 18px;
`;

const Field = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  font-size: 16px;
  ion-icon {
    font-size: 22px;
    color: ${(props) => props.theme.primary.main};
  }
`;

const FieldTitle = styled.div`
  color: #747474;
  margin-left: 5px;
`;

const FieldValue = styled.div`
  color: ${(props) => props.theme.primary.main};
  margin-left: auto;
`;

export default Profile;
